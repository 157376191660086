<template>
  <div class="liff-course-page">
    <div class="page__toolbar">
      <div class="toolbar-back-action" @click="backToMyCoursePage">
        <a-button type="link">
          <template #icon>
            <ArrowLeftOutlined :style="{ color: 'white', fontWeight: 'bold' }" />
          </template>
        </a-button>
      </div>
      <div class="toolbar-title">{{ courseData.name }}</div>
    </div>

    <div class="page__tabs">
      <a-tabs v-model:activeKey="activeTabKey" centered @change="changeTab">
        <a-tab-pane key="1" tab="課堂列表" :to="{ name: 'LiffCoursePage' }" />
        <a-tab-pane key="2" tab="班級公告" :to="{ name: 'LiffCourseAnnouncements' }" />
        <a-tab-pane key="3" tab="即時調查" :to="{ name: 'LiffCourseInstantSurveyList' }" />
        <a-tab-pane key="4" tab="相簿集" :to="{ name: 'LiffCourseAlbum' }" />
      </a-tabs>
    </div>

    <div class="page__body">
      <div class="body__wrapper" :style="wrapperStyle">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { ArrowLeftOutlined } from '@ant-design/icons-vue'
import { useRoute, useRouter } from 'vue-router'
import api from '@/apis'

export default defineComponent({
  components: {
    ArrowLeftOutlined
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    /* Tabs */
    const activeTabKey = ref('1');
    const changeTab = () => {
      if (activeTabKey.value == '1') router.push({ name: 'LiffCourseList' });
      if (activeTabKey.value == '2') router.push({ name: 'LiffCourseAnnouncements' });
      if (activeTabKey.value == '3') router.push({ name: 'LiffCourseInstantSurveyList' });
      if (activeTabKey.value == '4') router.push({ name: 'LiffCourseAlbum' });
    };

    /* Data */
    const courseData = ref({});
    // const usersData = ref([]);
    // const coachesData = ref([]);
    // const assistantsData = ref([]);

    /* Api */
    const getCourseData = (async () => {
      courseData.value = {};
      
      const response = await api.v1.course.findOne(route.params.course_id);
      courseData.value = response.data.result;
    });

    /* Mounted */
    onMounted(async () => {
      await getCourseData();
    });

    return {
      route,

      /* Tabs */
      activeTabKey,
      changeTab,

      /* Data */
      courseData,
      // coachesData,
      // assistantsData,
      // usersData
    }
  },
  computed: {
    wrapperStyle() {
      if (this.$route.name === 'LiffCourseAlbum') return { padding: 0 }
      else return { padding: '24px 12px 0px 12px' }
    }
  },
  methods: {
    /* Router */
    backToMyCoursePage() {
      this.$router.push({ name: 'LiffMyCourses' });
    }
  }
})
</script>

<style lang="scss">
.liff-course-page {
  min-height: 100vh;
  background: #EAEAEA;

  .page__toolbar {
    height: 57px;
    background: #3D3D3D;
    position: relative;

    .toolbar-back-action {
      position: absolute;
      left: 12px;
      top: 12px;
    }

    .toolbar-title {
      color: white;
      text-align: center;
      font-weight: 500;
      font-size: 1.5rem;
      letter-spacing: 0.0425rem;
      line-height: 57px;
    }
  }

  .page__tabs {
    padding: 0 20px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    background: white;
    position: sticky;
    top: 0;
    z-index: 9;

    .ant-tabs {
      .ant-tabs-nav {
        margin-bottom: 0;

        .ant-tabs-tab .ant-tabs-tab-btn {
          color: #424242;
          font-size: 1.1rem;
        }

        .ant-tabs-tab-active .ant-tabs-tab-btn {
          color: #FF8900;
        }

        .ant-tabs-ink-bar {
          background: #FF8900;
        }
      }
    }
  }

  .page__body {
    .body__wrapper {
      // padding: 24px 12px 0px 12px;
    }
  }
}
</style>